import { createReducer, Action, on } from '@ngrx/store';

import { loadOrders, loadOrdersSuccess, refreshOrders, setOrdersFilter } from './orders.actions';
import { addDays, startOfDay } from 'date-fns';
import { Orders } from '@assist/shared/data';

export const initialState: Orders = {
  loading: false,
  data: [],
  from: startOfDay(new Date()),
  to: addDays(new Date(), 7),
  resourcesNames: [],
  services: [],
  take: 10,
  skip: 0,
  showEventOrders: true,
  showResourceOrders: true,
};

const reducer = createReducer(
  initialState,
  on(loadOrders, (state, action) => {
    return { ...state, loading: true };
  }),
  on(refreshOrders, (state, action) => {
    return { ...state, data: [], skip: 0, take: 10 };
  }),
  on(setOrdersFilter, (state, action) => {
    return {
      ...state,
      from: action.from,
      to: action.to,
      resourcesNames: action.resourcesNames,
      showEventOrders: action.showEventOrders,
      showResourceOrders: action.showResourceOrders,
      services: action.services,
      take: 10,
      skip: 0,
      data: [],
    };
  }),
  on(loadOrdersSuccess, (state, action) => {
    return {
      ...state,
      data: [...state.data, ...action.orders],
      loading: false,
      take: action.take,
      skip: action.skip,
    };
  }),
);

export function ordersReducer(state: Orders, action: Action) {
  return reducer(state, action);
}
