import { NewsState } from './news.models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  allNewsLoaded,
  dashboardNewsLoaded,
  loadAllNews,
  loadDashbaordNews,
  loadNewsDetail,
  newsDetailLoaded,
  setNewsFilter,
  setNewsPage,
  setNewsPageSize,
} from './news.actions';

const initialState: NewsState = {
  allNews: {
    loading: false,
    data: [],
  },
  dashboardNews: {
    loading: false,
    data: [],
  },
  detail: {
    loading: true,
    data: null,
  },
  page: 1,
  pageSize: 5,
  total: 0,
  from: null,
  to: null,
};

export const reducer = createReducer(
  initialState,
  on(setNewsFilter, (state, { from, to }) => {
    return {
      ...state,
      from: from,
      to: to,
      page: 1,
    };
  }),
  on(setNewsPage, (state, { page }) => {
    return {
      ...state,
      page: page,
    };
  }),
  on(setNewsPageSize, (state, { pageSize }) => {
    return {
      ...state,
      pageSize,
      page: 1,
    };
  }),
  // Dashboard news
  on(loadDashbaordNews, (state, action) => {
    return {
      ...state,
      dashboardNews: {
        ...state.dashboardNews,
        loading: true,
      },
    };
  }),
  on(dashboardNewsLoaded, (state, { news }) => {
    return {
      ...state,
      dashboardNews: {
        data: news,
        loading: false,
      },
    };
  }),
  // All news
  on(loadAllNews, (state, action) => {
    return {
      ...state,
      allNews: {
        ...state.allNews,
        loading: true,
      },
    };
  }),
  on(allNewsLoaded, (state, { news, total }) => {
    return {
      ...state,
      allNews: {
        data: news,
        loading: false,
      },
      total: total,
    };
  }),

  on(loadNewsDetail, (state, action) => {
    return {
      ...state,
      detail: {
        ...state.detail,
        loading: true,
      },
    };
  }),
  on(newsDetailLoaded, (state, { news }) => {
    return {
      ...state,
      detail: {
        data: news,
        loading: false,
      },
    };
  }),
);

export function newsReducer(state: NewsState, action: Action) {
  return reducer(state, action);
}
