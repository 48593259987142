import { EnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { OrdersEffects, ordersReducer } from './store';

export const OrdersDataProviders: EnvironmentProviders[] = [
  provideState({
    name: 'orders',
    reducer: ordersReducer,
  }),
  provideEffects(OrdersEffects),
];
