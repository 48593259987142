import { EnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { newsReducer } from './store/news.reducers';
import { provideEffects } from '@ngrx/effects';
import { NewsEffects } from './store/news.effects';

export const NewsDataProviders: EnvironmentProviders[] = [
  provideState({
    name: 'news',
    reducer: newsReducer,
  }),
  provideEffects(NewsEffects),
];
