import { provideStoreDevtools } from '@ngrx/store-devtools';

export const extProviders = [
  provideStoreDevtools({
    name: 'Cue Store',
    trace: true,
    maxAge: 100,
    connectInZone: true,
  }),
];
