import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ConfigService, OrdersState } from '@assist/shared/data';
import { forkJoin, of } from 'rxjs';
import { loadMoreOrders, loadOrders, loadOrdersSuccess, refreshOrders, setOrdersFilter } from './orders.actions';
import { betterLatestFrom } from '@assist/shared/data';
import { orderSettings } from './orders.selectors';
import { OrdersService } from '../orders.service';

@Injectable()
export class OrdersEffects {
  refresh = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshOrders),
      betterLatestFrom((a) => this.store.select(orderSettings)),
      switchMap(([a, settings]) =>
        of(
          loadOrders({
            resourcesNames: settings.resourceNames,
            from: settings.from,
            to: settings.to,
            take: settings.take,
            skip: settings.skip,
            showEventOrders: settings.showEventOrders,
            showResourceOrders: settings.showResourceOrders,
            services: settings.services,
          }),
        ),
      ),
    ),
  );

  loadWhenFilterChanges = createEffect(() =>
    this.actions$.pipe(
      ofType(setOrdersFilter),
      map((action) =>
        loadOrders({
          resourcesNames: action.resourcesNames,
          take: 10,
          skip: 0,
          to: action.to,
          showEventOrders: action.showEventOrders,
          showResourceOrders: action.showResourceOrders,
          services: action.services,
          from: action.from,
        }),
      ),
    ),
  );

  load = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrders),
      switchMap((action) =>
        forkJoin([
          this.ordersService.getOrders({
            from: action.from,
            to: action.to,
            skip: action.skip,
            showEventOrders: action.showEventOrders,
            showResourceOrders: action.showResourceOrders,
            services: action.services,
            resourceNames: action.resourcesNames,
            take: action.take,
          }),
          of(action),
        ]),
      ),
      map(([x, action]) =>
        loadOrdersSuccess({
          orders: x,
          take: action.take,
          skip: action.skip,
        }),
      ),
    ),
  );

  loadMore = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreOrders),
      betterLatestFrom((a) => this.store.select(orderSettings)),
      switchMap(([value, settings]) =>
        of(
          loadOrders({
            resourcesNames: settings.resourceNames,
            from: settings.from,
            to: settings.to,
            take: settings.take,
            skip: settings.skip + settings.take,
            showEventOrders: settings.showEventOrders,
            showResourceOrders: settings.showResourceOrders,
            services: settings.services,
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<OrdersState>,
    private ordersService: OrdersService,
    private configService: ConfigService,
  ) {}
}
