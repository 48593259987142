import { enableProdMode, Type } from '@angular/core';
import { environment } from './environments/environment';
import { ConfigService } from '@assist/shared/data';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app.config';
import { forkJoin, from } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { ServerType } from '@cue/api';
import { CalendarsService } from '@cue/calendars';

if (environment.production) {
  enableProdMode();
}

const configService = new ConfigService();

const calendarImplementations: Record<ServerType, () => Promise<Type<CalendarsService>>> = {
  gspace: () => import('@cue/calendars/gspace').then((x) => x.GSpaceCalendarService),
  ews: () => import('@cue/calendars/ews').then((x) => x.EWSCalendarService),
  'touchone-calendar': () => import('@cue/calendars/touchone').then((x) => x.TouchoneCalendarService),
  o365: () => import('@cue/calendars/o365').then((x) => x.O365CalendarService),
};

const availableCodes = {
  ca: () => [import('@angular/common/locales/ca'), import('@progress/kendo-angular-intl/locales/ca/all')],
  ua: () => [import('@angular/common/locales/ru-UA'), import('@progress/kendo-angular-intl/locales/ru-UA/all')],
  fr: () => [import('@angular/common/locales/fr'), import('@progress/kendo-angular-intl/locales/fr/all')],
  no: () => [import('@angular/common/locales/nb'), import('@progress/kendo-angular-intl/locales/nb/all')],
  nb: () => [import('@angular/common/locales/nb'), import('@progress/kendo-angular-intl/locales/nb/all')],
  en: () => [import('@angular/common/locales/en'), import('@progress/kendo-angular-intl/locales/en/all')],
  de: () => [import('@angular/common/locales/de'), import('@progress/kendo-angular-intl/locales/de/all')],
  cs: () => [import('@angular/common/locales/cs'), import('@progress/kendo-angular-intl/locales/cs/all')],
  es: () => [import('@angular/common/locales/es'), import('@progress/kendo-angular-intl/locales/es/all')],
};

const defaultImport = () => [import('@angular/common/locales/en'), import('@progress/kendo-angular-intl/locales/en/all')];

function getLocaleId(): [Promise<any>, Promise<any>] {
  const userLang = navigator.language || (navigator as any).userLanguage;
  const code = userLang.split('-')[0];
  const foundCode = availableCodes[code];
  const returnCode = foundCode ? foundCode() : defaultImport();
  return returnCode;
}

const bootstrap = (calendarService: Type<CalendarsService>) =>
  bootstrapApplication(AppComponent, appConfig(configService, calendarService));

const promises = getLocaleId();
forkJoin(promises.map((x) => from(x))).subscribe((lang) => {
  registerLocaleData(lang[0].default);
  configService.prebootstrapLoad().then(
    (_) => {
      const calendarService = calendarImplementations[configService.value.serverType]().then((calendarService) => {
        bootstrap(calendarService).catch((err) => console.log(err));
      });
    },
    (error) => {
      window.alert(error);
    },
  );
});
