import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { betterLatestFrom } from '@assist/shared/data';
import { map, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState, ConfigService } from '@assist/shared/data';
import { NewsService } from '../news.service';
import {
  allNewsLoaded,
  dashboardNewsLoaded,
  loadAllNews,
  loadDashbaordNews,
  loadNewsDetail,
  newsDetailLoaded,
  setNewsFilter,
  setNewsPage,
  setNewsPageSize,
} from './news.actions';
import { newsFilter, newsFilterWithPageAndPageSIZE } from './news.selectors';

@Injectable()
export class NewsEffects {
  LoadDashbaordNews = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDashbaordNews),
      switchMap((a) => this.newsService.getNews(0, 10)),
      map((news) =>
        dashboardNewsLoaded({
          news: news.data,
        }),
      ),
    ),
  );

  loadAllNews = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllNews),
      betterLatestFrom((_) => this.store.select(newsFilterWithPageAndPageSIZE)),
      switchMap(([a, _newsFilter]) =>
        // this.newsService.getNews((_newsFilter.page -1) * 5, 5, _newsFilter.from, _newsFilter.to)
        this.newsService.getNews(0, _newsFilter.pageSize, _newsFilter.from, _newsFilter.to),
      ),
      map((r) =>
        allNewsLoaded({
          news: r.data,
          total: r.total,
        }),
      ),
    ),
  );

  loadNewsOnPage = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewsPage),
      betterLatestFrom((_) => this.store.select(newsFilterWithPageAndPageSIZE)),
      switchMap(([a, _newsFilter]) =>
        this.newsService.getNews((a.page - 1) * _newsFilter.pageSize, _newsFilter.pageSize, _newsFilter.from, _newsFilter.to),
      ),
      map((r) =>
        allNewsLoaded({
          news: r.data,
          total: r.total,
        }),
      ),
    ),
  );

  loadNewsOnPageSize = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewsPageSize),
      betterLatestFrom((_) => this.store.select(newsFilterWithPageAndPageSIZE)),
      switchMap(([a, _newsFilter]) =>
        this.newsService.getNews((_newsFilter.page - 1) * a.pageSize, a.pageSize, _newsFilter.from, _newsFilter.to),
      ),
      map((r) =>
        allNewsLoaded({
          news: r.data,
          total: r.total,
        }),
      ),
    ),
  );

  loadAllNewsWhenFilterSet = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewsFilter),
      map((_) => loadAllNews()),
    ),
  );

  loadDetailNews = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNewsDetail),
      switchMap((a) => this.newsService.getNewsById(a.id)),
      map((news) =>
        newsDetailLoaded({
          news: news,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private configService: ConfigService,
    private newsService: NewsService,
  ) {}
}
