import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiService, pairWebex } from '@cue/api';

@Injectable({
  providedIn: 'root',
})
export class WebexCallbackGuard implements CanActivate {
  constructor(
    public router: Router,
    private apiService: ApiService,
  ) {}

  closeSubject$: Subject<any> = new Subject();

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const code = route.queryParams['code'];
    this.apiService
      .call(
        pairWebex({
          code: code,
          redirectUrl: location.origin + '/web-ex/callback',
        }),
      )
      .subscribe((response) => {
        window.close();
      });

    return this.closeSubject$.pipe(take(1));
  }
}
