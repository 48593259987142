import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService, ConfigService } from '@assist/shared/data';
import { BehaviorSubject, Observable, of, throwError, catchError, filter, switchMap, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private oauthTokenObtaining = false;
  private obtainTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private apiTokenObtaining = false;
  private apiTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private apiTokenRefreshing = false;
  private oauthTokenRefreshing = false;

  private apiRefreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  msUrls = ['https://graph.microsoft.com'];
  googleUrls = [
    'https://www.googleapis.com',
    'https://people.googleapis.com',
    'https://lh3.googleusercontent.com',
    'https://admin.googleapis.com',
  ];
  apiUrls: string[] = [];

  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private translateService: TranslocoService,
  ) {
    this.apiUrls.push(configService.value.apiURL);
    if (configService.value.calendarURL && configService.value.calendarURL != '') {
      this.apiUrls.push(configService.value.calendarURL);
    }
  }

  private addTokenHeader(req: HttpRequest<any>, token: string) {
    return req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + token),
    });
  }

  isToAPI(url: string) {
    return this.apiUrls.some((apiUrl) => url.toUpperCase().startsWith(apiUrl.toUpperCase()));
  }

  isToMS(url: string) {
    return this.msUrls.some((apiUrl) => url.toUpperCase().startsWith(apiUrl.toUpperCase()));
  }

  isToGoogle(url: string) {
    return this.googleUrls.some((apiUrl) => url.toUpperCase().startsWith(apiUrl.toUpperCase()));
  }

  handle401API(request: HttpRequest<any>, next: HttpHandler): any {
    const accessToken = this.authService.getApiToken();
    const refreshToken = this.authService.getApiRefreshToken();

    if (accessToken == null || refreshToken == null) {
      this.authService.logout();
    }

    if (!this.apiTokenRefreshing) {
      this.apiTokenRefreshing = true;
      this.apiTokenSubject.next(null);
      return this.authService.refreshTokenRequest().pipe(
        switchMap((response: any) => {
          if (response.success) {
            this.authService.saveApiToken(response.data.token);
            this.authService.saveApiRefreshToken(response.data.refreshToken);
            this.apiTokenRefreshing = false;
            this.apiTokenSubject.next(response.data.token);
            const authReq = this.addTokenHeader(request, response.data.token);
            return next.handle(authReq).pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.status == 401) {
                  this.authService.logout();
                }
                return throwError(err);
              }),
            );
          } else {
            this.apiTokenRefreshing = false;
            this.authService.logout();
            return throwError('Cannot refresh token');
          }
        }),
      );
    } else {
      return this.apiTokenSubject.pipe(
        filter((x) => x !== null),
        take(1),
        switchMap((token) => {
          const authReq = this.addTokenHeader(request, token);
          return next.handle(authReq).pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status == 401) {
                this.authService.logout();
              }

              return throwError(err);
            }),
          );
        }),
      );
    }
  }

  private handle401(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.oauthTokenRefreshing) {
      this.oauthTokenRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshOAuthTokenFromTheServer().pipe(
        switchMap((response) => {
          if (response.success) {
            this.authService.setOAuthToken(response.data!);
            this.oauthTokenRefreshing = false;
            this.refreshTokenSubject.next(response.data);
            const authReq = this.addTokenHeader(request, response.data!);
            return next.handle(authReq).pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.status == 401) {
                  this.authService.logout();
                }
                return throwError(err);
              }),
            );
          } else {
            this.oauthTokenRefreshing = false;
            this.authService.logout();
            return throwError('Cannot refresh token');
          }
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((x) => x !== null),
        take(1),
        switchMap((token) => {
          const authReq = this.addTokenHeader(request, token);
          return next.handle(authReq).pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status == 401) {
                this.authService.logout();
              }
              return throwError(err);
            }),
          );
        }),
      );
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let authReq: any = null;

    // U requestu k nam na API je nutne prilozit token, pokud server vrati 401, je nutne proste odhlasit
    if (this.isToAPI(req.url)) {
      const authReq = this.addTokenHeader(req, this.authService.getApiToken()!);
      return next.handle(authReq).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status == 401) {
            if (authReq.url.search('account/ews-login') === -1) {
              return this.handle401API(authReq, next);
            } else {
              return throwError(err);
            }
          }
          if (err.status == 403) {
            const translation = this.translateService.translate('common.contactToCheckAccount');
            window.alert(translation);
          }
          return throwError(err);
        }),
      );
    }

    // U requestu ke Google / MS je nutne ziskat token ze serveru, pokud neni a handlit 401 pooci server refreshe
    if (this.isToMS(req.url) || this.isToGoogle(req.url)) {
      authReq = req;
      const token = this.authService.getOAuthToken();

      if (token != null) {
        // mame token, je potreba ho pouzit
        authReq = this.addTokenHeader(authReq, token);
        return next.handle(authReq).pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status == 401) {
              return this.handle401(authReq, next);
            } else {
              return throwError(error);
            }
          }),
        );
      } else {
        // token nemame, bude nutne ho ziskat

        // Pokud se jeste neziskava tak ho ziskame
        if (!this.oauthTokenObtaining) {
          this.oauthTokenObtaining = true;
          this.obtainTokenSubject.next(null);
          return this.authService.getOAuthTokenFromTheServer().pipe(
            switchMap((response) => {
              if (response.success) {
                this.authService.setOAuthToken(response.data!);
                this.oauthTokenObtaining = false;
                this.obtainTokenSubject.next(response.data);
                authReq = this.addTokenHeader(authReq, this.authService.getOAuthToken()!);
                return next.handle(authReq).pipe(
                  catchError((error) => {
                    if (error instanceof HttpErrorResponse && error.status == 401) {
                      return this.handle401(authReq, next);
                    } else {
                      return throwError(error);
                    }
                  }),
                );
              } else {
                this.oauthTokenObtaining = false;
                this.authService.logout();
                return throwError('Could not get oAuth token from API');
              }
            }),
          );
        } else {
          // Uz nekdo ziskava token, tak na to cekame
          return this.obtainTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) => {
              authReq = this.addTokenHeader(authReq, token);
              return next.handle(authReq).pipe(
                catchError((error) => {
                  if (error instanceof HttpErrorResponse && error.status == 401) {
                    return this.handle401(authReq, next);
                  } else {
                    return throwError(error);
                  }
                }),
              );
            }),
          );
        }
      }
    }

    // Pokud to neni ani k nam, ani k OUATH resource serveru, tak s tim nedelam nic.
    return next.handle(req);
  }
}
